import React, {useState, useEffect} from 'react';
import { Image, Card, Accordion } from 'react-bootstrap';
import { semesters } from '../objects';
export default function Education(){
    const [semesterIdx, setSemesterIdx] = useState(-1);
    let reversedSemesters = [...semesters].reverse();

    useEffect(() => {
        const inputElement = document.getElementById(`semesterDescription${semesterIdx}`);
        if(inputElement){
            inputElement.focus();
            console.log(inputElement)
        }
    }, [semesterIdx])

    const folderStyle = {
        width: '60vmin',
        height: '60vmin',
        backgroundColor: '#A3AAAE',
        borderRadius: '48px',
        display: 'flex',
        flexWrap: 'wrap', 
        justifyContent: 'flex-start', 
        alignItems: 'flex-start', 
        padding: 20,
        overflow: 'hidden',
      };
    

  const widgetStyle = {
    width: '60vmin',
    height: '60vmin',
    backgroundColor: '#A3AAAE',
    borderRadius: '48px',
    padding: 30
  };
  const widgetTitle = {
    display: "flex",
    flexDirection: "row",
    marginBottom: 20
    // alignItems: "",
  }
  const widgetTitleText = {
    display: "flex",
    flexDirection: "column"
  }
  const pageContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
  }
  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: 100,
  }
  const appStyle = {
    width: '13vmin',
    height: '13vmin',
    borderRadius: 18,
    marginRight: '2.5vmin',
    marginLeft: '2.5vmin',
    backgroundColor:'#9A2A2A',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'

  }
  const appContainer = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  }
  const appNameText = {
    color: "white"
  }
  const semesterSummary = {
    // display: 'flex',
    // alignItems: 'center',
    // flexDirection: 'column',
    margin: 60
  }

  return (
    <div>
        <div style={pageContainerStyle}>
        <div style={containerStyle}>
            <div style={folderStyle}>
            {
                reversedSemesters.map((s, index) => (
                <div style={appContainer} key={index}>
                    <div style={appStyle} className='blur-image' onClick={() => {
                        setSemesterIdx(index)
                        }}>
                        <h1 style={appNameText}>{s.iconAbv}</h1>
                        </div>
                    <p >{s.iconName}</p>
                </div>
                ))
            }
            </div>
            <h1 className="white-text">Semesters</h1>

        </div>
        <div style={containerStyle}>
            <div style={widgetStyle}>
                <div>
                    <div style={widgetTitle}>
                        <Image src={"bucky.jpg"} style={appStyle} className='blur-image' onClick={() => {
                            window.location.href = "https://www.wisc.edu/"
                        }}></Image>
                        <div style={widgetTitleText}>
                            <h2>UW Madison</h2>
                            
                        </div>
                    </div> 
                    <div>
                    <p><strong>Major:</strong> Computer Science and Finance</p>
                            <p><strong>Graduation Date:</strong> May 2025</p>
                            <p><strong>GPA:</strong> 3.7</p>
                            <p><strong>Skills:</strong> Java, Python, C, React, SQL, Algorithms, Data Structures, Operating Systems, AI, Databases, Derivitave Securities, Excel, Financial Markets, Crypto, Portfolio Management</p>
                    </div>
                </div>
            </div>
        </div>
        </div>
        {semesterIdx >=0 ? <Card className="mb-4 "style={semesterSummary} id={`semesterDescription${semesterIdx}`} >
            <Card.Header>
                <h2>{ reversedSemesters[semesterIdx].termDescription }</h2>
            </Card.Header>
                    <Accordion>
                    {
                        reversedSemesters[semesterIdx].courses.map((c, i) => (
                            <Accordion.Item eventKey={i} key={i}>
                                <Accordion.Header>{c.courseShortDescription} {c.courseNum} - {c.courseDescription}</Accordion.Header>
                                <Accordion.Body>
                                    <p><strong>Description:</strong> {c.description}</p>
                                    <p><strong>Credits:</strong> {c.credits}</p>

                                </Accordion.Body>
                            </Accordion.Item>
                        ))
                    }
                    </Accordion>
        </Card> : <></>}
    </div>
  );
}